app.controller('ProfileAuthController', ['$scope', '$http', '$window', '$httpParamSerializer', 'Backend', '$q',
    function($scope, $http, $window, $httpParamSerializer, Backend, $q) {
    this.hasError = false;
    this.form = {};
    this.errors = {};
    this.page = 'register';
    var self = this;

    this.goToBeneficious = function (beneficios_url) {
        $http.post(beneficios_url+'/authenticate', {'Token': $http.defaults.headers.common.Authorization}).then(function(response){
            location.href = response.data;
        }, function(errors) {
            console.log(errors);
        });
    };

    this.doRegister = function () {
        self.hasError = false;
        $http.post('/site-register', this.form, {}).then(function(response){
            self.page = 'success';
            if (response.data['redirect_url']) {
                $window.location.href = response.data['redirect_url'];
            } else {
                $window.location.reload();
            }
        }, function(errors) {
            self.hasError = true;
            self.errors = errors.data;
        })

    };
}]);