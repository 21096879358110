import axios from 'axios';

app.controller('MainController', ['$scope', 'Notification', '$translate', '$http', '$interval', '$timeout', '$sce', '$sanitize', function ($scope, Notification, $translate, $http, $interval, $timeout, $sce, $sanitize) {
    const self = this;

    this.changeLanguage = function (lang) {
        $translate.use(lang);
    };

    $scope.trustHtml = function (html) {
        return $sce.trustAsHtml($sanitize(html));
    }

    this.keepSession = function () {
        axios.get('/site-keep-session', {
            headers: {
                Authorization: $http.defaults.headers.common['Authorization']
            }
        }).then(response => {
            if (response.data && response.data.url) {
                axios.get(response.data.url, {
                    headers: {
                        Authorization: $http.defaults.headers.common['Authorization']
                    }
                }).then(() => {
                    self.logoutSession();
                }).catch((err) => {
                    if (err && err.response && err.response.status === 401) {
                        self.logoutSession();
                    } else {
                        $timeout(self.keepSession, 1500);
                    }
                });
            }
        }).catch(err => {
            console.error(err);
            $timeout(self.keepSession, 1500);
        });
    }

    this.initSession = function (sessionExpiresAt) {
        if (!sessionExpiresAt) {
            return;
        }
        let timeout = sessionExpiresAt * 1000 - new Date().getTime();
        if (timeout < 0) {
            timeout = 0;
        }
        if (GLOBALS.AUTH_STRICT_LOGOUT) {
            self.keepSession();
        }
        self.setSessionRefresh(timeout / 1000);
    };

    this.setSessionRefresh = function (timeoutSeconds) {
        let to = timeoutSeconds * 1000;
        if (to > 3600000) {
            // setTimeout breaks with very large timeouts
            to = 3600000;
        }
        $timeout(function () {
            self.continueSession();
        }, to);
    };

    this.logoutSession = function () {
        deleteTokenJwtAndRedirectToLogout();
    };

    this.continueSession = function () {
        $http.post('/site-refresh').then(function (response) {
            Formio.token = 'Bearer ' + response.data.jwt_token;
            $http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.jwt_token;
            localStorageSetItemWithFullWarning('formioToken', $http.defaults.headers.common['Authorization']);
            self.setSessionRefresh(response.data['timeout']);
        }, function () {
            self.logoutSession();
        });
    };
}]);