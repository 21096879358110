app.provider("SiteConfiguration", function() {
    this.setBackendUrl = function(url) {
        this.backend_url = url;
    };

    function SiteConfiguration(backend_url, service_locations) {
        this.backend_url = backend_url;
        this.service_locations = service_locations;

        this.getBackendUrl = function () {
            return this.backend_url;
        }
    }

    this.$get = [function unicornLauncherFactory() {
        return new SiteConfiguration(this.backend_url, this.service_locations);
    }];
});
