import 'angular';

(function() {

  angular
    .module('app')
    .factory('interceptorNgProgress', ['$q', 'ngProgressFactory', '$rootScope', interceptorNgProgress]);

  /** @ngInject */
  function interceptorNgProgress($q, ngProgressFactory, $rootScope) {
    var myInterceptor = {};
    var requestCounter=0;
    var ng_progress_instance = null;

    // On request success
    myInterceptor.request = function(config) {
      if(config.hideRequest) {
        return config || $q.when(config);
      }
      requestCounter++;
      if (!ng_progress_instance){
        ng_progress_instance = ngProgressFactory.createInstance();
        ng_progress_instance.setColor('#4CAF50');
      }

      if (requestCounter == 1) {
        ng_progress_instance.start();
      }

      return config || $q.when(config);
    };

    // On request failure
    myInterceptor.requestError = function(rejection) {
      //decrement counter as request is failed
      if (!response.config.hideRequest) {
        requestCounter--;
        hideLoaderIfNoCall();
      }

      // Return the promise rejection.
      return $q.reject(rejection);
    };

    // On response success
    myInterceptor.response = function(response) {
      //decrement counter as request is failed
      if (!response.config.hideRequest) {
        requestCounter--;
        hideLoaderIfNoCall();
      }

      // Return the response or promise.
      return response || $q.when(response);
    };

    // On response failture
    myInterceptor.responseError = function (rejection) {
      //decrement counter as request is failed
      if (!rejection.config.hideRequest) {
        requestCounter--;
        hideLoaderIfNoCall();
      }
      // Return the promise rejection.
      return $q.reject(rejection);
    };

    function hideLoaderIfNoCall(){
      // check if counter is zero means
      // no request is in process

      // use triple equals see why http://goo.gl/2K4oTX
      if(requestCounter === 0)
        ng_progress_instance.complete();
        //$rootScope.$emit('ng-progress-complete');
    };

    return myInterceptor;
  }

})();


(function() {

  angular
    .module('app')
    .factory('httpErrorFactory', ['$q', '$injector', '$rootScope', '$translate', httpErrorFactory]);

  /** @ngInject */
  function httpErrorFactory($q, $injector, $rootScope, $translate) {
    var errorInterceptor = {};
    var statuses = [];
    errorInterceptor.responseError = function (rejection) {
      var status = rejection.status;

      if (statuses.indexOf(status) !== -1){
        return $q.reject(rejection);
      }

      statuses.push(status);

      var notifier = $injector.get('Notification');
      var msg = {};
      msg.replaceMessage = false;
      msg.message = false;

      if (status == -1) {
        msg.message = 'ERR_CONNECTION_REFUSED';
      }
      else if (status == 401){
        //msg.message = "NetworkError: " + status + " " + rejection.statusText;
        msg.message = "Your session has expired. Please log in again"
        var $state = $injector.get('$state');
        $state.go("login");
      } else if (![409, 403, 404].includes(status)) {
        msg.message = $translate.instant('Technical error occurred');
        if (rejection.data && rejection.data.message) {
          msg.message += ': ' + rejection.data.message;
        }
      }

      if (msg.message) {
        notifier.error(msg);
      }

      // Return the promise rejection.
      return $q.reject(rejection);
    };
    return errorInterceptor;
  }

})();
