app.directive('fileStatus', ['$compile', '$translate', '$q', function ($compile, $translate, $q) {
    return {
        restrict: 'E',
        scope: {
            status: '=',
            history: '=',
            statusBadgeStyle: '@'
        },
        link: function (scope, element, attrs, controllers) {
            const status = scope.status,
                  history = scope.history,
                  buf = [],
                  status_badge_style = scope.statusBadgeStyle;
            /**
             * history is always visible
             * this component suffers from an issue of being used in 2 different contexts, there should be really 2 components:
             *  1. Showing the status from role/task in history
             *  2. Showing the status from role/task as final result from at given role/task (so, possibly flatten)
             *
            */
            if (!history && status.hidden) {
                return '';
            }
            /*
            * filepending
            * filevalidated
            * filedecline
            * filereject
            * cancelled
            * ended
            * */
            let label_type = "";

            switch (status.status) {
                case 'filepending':
                    label_type = 'status-info';
                    break;
                case 'filereject':
                case 'cancelled':
                case 'ended':
                    label_type = 'status-danger';
                    break;
                case 'filedecline':
                    label_type = 'status-warning';
                    break;
                case 'filevalidated':
                    label_type = 'status-success';
                    break;
                default:
                    label_type = 'status-default';
                    break;
            }

            const trans_promises = [],
                  registrations_list = [];
            if (status.registrations !== undefined && status.registrations.length) {
                let pre = ' - ';
                angular.forEach(status.registrations, function (reg_name) {
                    trans_promises.push($translate(reg_name).then(function (translation_text) {
                        registrations_list.push(pre + translation_text);
                        pre = ', ';
                    }, function (translation_id) {
                        registrations_list.push(pre + translation_id);
                        pre = ', ';
                    }));
                });
            }

            let translatedName = '';
            if (status.name) {
                translatedName = $translate.instant(status.name);
                if (status.name === translatedName) {
                    // Translation made no difference, try looking by shortname
                    translatedName = $translate.instant(status.shortname);
                }
            } else if (status.shortname) {
                translatedName = $translate.instant(status.shortname);
            }
            element = $(element);

            $q.all(trans_promises).then(function () {
                buf.push('<span class="status-badge ');
                buf.push(status_badge_style);
                buf.push(' ');
                buf.push(label_type);
                buf.push('" data-toggle="tooltip" title="');
                buf.push(translatedName);
                angular.forEach(registrations_list, function (reg_name) {
                    buf.push(reg_name);
                });
                buf.push('">');
                buf.push(translatedName);
                buf.push('</span> ');
                const html = buf.join("");
                element.html(html);
                $compile(element.contents())(scope);

                try {
                    element.find('[data-toggle="tooltip"]').tooltip();   
                } catch (error) {
                    console.log(error);
                }
            }, function (reason) {
                /* no data so we represent empty element */
                const html = buf.join("");
                element.html(html);
                $compile(element.contents())(scope);

                try {
                    element.find('[data-toggle="tooltip"]').tooltip();
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };
}]);

app.directive('turtleMapping', ['$templateCache', '$compile', '$filter',
    function($templateCache, $compile, $filter) {
    return {
        restrict: 'A',
        scope: {
            obj:"=",
            key:"=",
            templatePrefix:"="
        },

        link: function(scope, element, attrs, controller) {
            // Step 0: get HTML template from cache
            var prefix = scope.templatePrefix !== undefined ? scope.templatePrefix+'-' : "";
            var template_name = prefix+scope.obj.type+'.html';
            var template_string = $templateCache.get(template_name);
            if (template_string === undefined) {template_string = $templateCache.get(prefix+'default.html');}

            // Step 1: parse HTML into DOM element
            var template_dom = angular.element(template_string);

            // Step 2: compile the template
            var linkFn = $compile(template_dom);

            // Step 3: link the compiled template with the scope.
            var template = linkFn(scope);

            element.push(template);
        }
    };
}]);

var renderTurtleFile = function (scope, element, attrs, controller) {
    var file = scope.file;
    var buf = ['<a class="fancy img-thumbnail '];

    if (file.file_type == 'application/pdf') {
        buf.push('size-3 border-gray border-lg');
    }

    if (!attrs['clickFn']) {
        buf.push('" href="' + file.file_url + '" target="_blank">');
    } else {
        buf.push('" href="javascript:void(0)">');
    }

    if (file.file_type == 'application/pdf') {
        buf.push('<i class="fa fa-file-pdf fa-3x"></i>');
    } else if (file.file_type && file.file_type.startsWith('image')) {
        buf.push('<img class="img-responsive" src="' + file.file_url + '">');
    } else {
        buf.push('<i class="fa fa-file fa-3x"></i>');
    }

    buf.push('</a>');
    var html = buf.join("");
    element.html(html);

    /*add event listener if needed*/
    if (attrs['clickFn'] != undefined) {
        element[0].addEventListener('click', scope.clickFn);
    }
}

app.directive('turtleFile', ['$templateCache', '$compile', '$filter',
    function($templateCache, $compile, $filter) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            file:"=",
            waitonscoperesolved:"=",
            clickFn:"&"
        },

        link: function(scope, element, attrs, controller) {
            if (scope.waitonscoperesolved) {
                return scope.$on('scopeResolved', function (ev, data) {
                    if (data && data.skipImageRender) return;
                    renderTurtleFile(scope, element, attrs, controller);
                });
            }
            renderTurtleFile(scope, element, attrs, controller);
        }
    };
}]);

app.directive('turtleSelect', ['$compile', function($compile) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            component:"=",
            data:"=",
            submission: "=",
        },
        controller: ['$scope', '$interpolate', '$http', function($scope, $interpolate, $http) {
            function refreshValue() {
                if ($scope.component.dataSrc == 'url') {
                    var loadUrl = $interpolate($scope.component.data.url)({
                        data: $scope.submission ? $scope.submission.data : $scope.data,
                        row: $scope.data || {},
                    });
                    console.log('The loadUrl', $scope.component.data.url, loadUrl, $scope, {
                        data: $scope.submission ? $scope.submission.data : $scope.data,
                        row: $scope.data || {},
                    });

                    var valueProp = $scope.component.valueProperty ? $scope.component.valueProperty : 'id';
                    $http.get(loadUrl).then(function (valueData) {
                        var values = valueData.data.values ? valueData.data.values : valueData.data;
                        var dataId = $scope.data[$scope.component.key];
                        angular.forEach(values, function (remoteValue) {
                            if (remoteValue[valueProp] == dataId) {
                                $scope.item = remoteValue;
                            }
                        })
                    })
                }
                if ($scope.component.dataSrc == 'values') {
                    angular.forEach($scope.component.data.values, function (valueData) {
                        if (valueData.value == $scope.data[$scope.component.key]) {
                            // console.log(valueData);
                            $scope.item = valueData;
                        }
                    })
                }
            }

            var dataWatch = $scope.$watch('data.' + $scope.component.key, function (value) {
                if (value) {
                    refreshValue();
                }
            });
        }],
        link: function(scope, element) {
            if (scope.component.template) {
                element.append($compile(angular.element(scope.component.template))(scope));
            }
        }
    };
}]);

app.directive("scrollHeightDetector", ['$rootScope', function ($rootScope) {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            scope.$watch(function () { return element[0].scrollHeight;},
                function () {
                    function inIframe() {
                        try { return window.self !== window.top;
                        } catch (e) { return true; }
                    }
                    var in_iframe = inIframe();
                    if (in_iframe){
                        var client = element[0].clientHeight;
                        var scroll = element[0].scrollHeight;
                        var diff = Math.abs(scroll - client);

                        if ($rootScope.scroll_height === undefined) { $rootScope.scroll_height = 0; }
                        if ($rootScope.scroll_height_diff === undefined) { $rootScope.scroll_height_diff = diff; }

                        var scroll_diff = Math.abs(scroll - $rootScope.scroll_height);
                        if ($rootScope.scroll_height !== scroll && scroll_diff !== diff) {
                            $rootScope.scroll_height = scroll;
                            $rootScope.scroll_height_diff = diff;
                        }
                    }
                }
            );
        }
    }
}]);

app.directive('transactionStatus', ['$compile', '$translate', '$q', function ($compile, $translate, $q) {
    return {
        restrict: 'E',
        scope: {
            status: '='
        },
        link: function (scope, element, attrs, controllers) {
            var status = scope.status;
            var tS = GLOBALS.TRANSACTION_STATUS;
            var title = "";
            var label_type = "";
            var trans_promises = [];

            switch (status) {
                case tS.PENDING:
                    label_type = 'status-info';
                    titlePromise = $translate("Transaction Pending");
                    break;
                case tS.FAIL:
                    label_type = 'status-danger';
                    titlePromise = $translate("Transaction Fail");
                    break;
                case tS.ERROR:
                    label_type = 'status-danger';
                    titlePromise = $translate("Transaction Error");
                    break;
                case tS.OK:
                    label_type = 'status-success';
                    titlePromise = $translate("Transaction Processed");
                    break;
                default:
                    label_type = 'status-default';
                    titlePromise = $translate("Transaction Unknown");
                    break;
            }

            trans_promises.push(titlePromise.then(function (translation_text) {
                title = translation_text;
            }, function (translation_id) {
                title = translation_id;
            }));

            element = $(element);
            var buf = [];

            $q.all(trans_promises).then(function () {
                buf.push('<span class="status-badge status-bg');
                buf.push(label_type);
                buf.push('" >');
                buf.push($translate.instant(title));
                buf.push('</span> ');
                var html = buf.join("");
                element.html(html);
                $compile(element.contents())(scope);
            }, function (reason) {
                /* no data so we represent empty element */
                var html = buf.join("");
                element.html(html);
                $compile(element.contents())(scope);
            });
        }
    };
}]);

app.directive('onLastRepeat', function () {
    return function (scope, element, attrs) {
        if (scope.$last)
            setTimeout(function () {
                dispatchEvent(new CustomEvent("gotServiceRoles"));
            }, 1);
    };
});